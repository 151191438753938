<ion-item
  lines="none"
  [class]="border ? 'border' : ''"
  [color]="light ? 'secondary' : ''"
  [class.light]="light"
  [class.ion-margin-horizontal]="margin"
>
  <ion-col>
    <ion-label position="stacked" *ngIf="package.included_guests"
      >Up to {{ package.included_guests }} Guests</ion-label
    >
    <ng-container *ngIf="showAvail === true">
      <ion-badge
        class="soldbadge"
        size="large"
        *ngIf="
          availabil != undefined &&
          availabil.available == false &&
          showAvail == true
        "
        >Sold Out</ion-badge
      >
      <ion-badge
        class="soldbadge"
        size="large"
        *ngIf="
          availabil != undefined &&
          availabil.available == true &&
          showAvail == true
        "
        >Available</ion-badge
      >

      <p *ngIf="availabil == undefined" class="soldbadge">
        <ion-skeleton-text
          [animated]="true"
          style="width: 50px; height: 20px"
        ></ion-skeleton-text>
      </p>
    </ng-container>
    <ion-badge
      class="soldbadge"
      size="large"
      *ngIf="!showAvail && package.chip"
      >{{ package.chip }}</ion-badge
    >

    <ion-row class="ion-justify-content-between maxwidth">
      <ion-label class="title"> {{ package.name }}</ion-label>

      <ion-label class="title" *ngIf="price">{{
        package.min_customer_liability | currency: (currency$ | async)
      }}</ion-label>
    </ion-row>
  </ion-col>
  <ng-content></ng-content>
  <ion-button
    [style.margin-bottom.px]="-4"
    *ngIf="exitable"
    (click)="exit.emit()"
    slot="end"
    fill="clear"
  >
    <ion-icon slot="icon-only" color="light" name="close-outline"></ion-icon>
  </ion-button>
</ion-item>
