import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
//import { ReactiveComponentModule } from "@ngrx/component";
import { AddItemRowComponent } from "./non-standard/add-item-row/add-item-row.component";
import { CartItemComponent } from "./cart-item/cart-item.component";
import { FabActionsComponent } from "./fab-actions/fab-actions.component";
import { HeaderCartComponent } from "./non-standard/header-cart/header-cart.component";
import { HorizontalScrollerComponent } from "./non-standard/horizontal-scroller/horizontal-scroller.component";
import { UserTypePipeModule } from "../pipes/user-type.pipe";
import { MenuLoggedOutComponent } from "./non-standard/menu-logged-out/menu-logged-out.component";
import { ModifiersComponent } from "./modifiers/modifiers.component";
import { PageLoadingComponent } from "./non-standard/page-loading/page-loading.component";
import { PaymentFormGratuityComponent } from "./non-standard/payment-form-gratuity/payment-form-gratuity.component";
import { PaymentFormMethodComponent } from "./non-standard/payment-form-method/payment-form-method.component";
import { SplashScreenComponent } from "./non-standard/splash-screen/splash-screen.component";
import { filterfooter } from "./non-standard/filter-footer/filter-footer.component";
import { ModalBaseComponent } from "./non-standard/modal-base/modal-base.component";
import { MapComponent } from "./map/map.component";
import { TableCardComponent } from "./table-card/table-card.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { TreatMeInfluencerHeaderComponent } from "./treat-me-influencer-header/treat-me-influencer-header.component";
import { RandomiseUrlPipeModule } from "../pipes/randomise-url/randomise-url.pipe";
import { OnboardingModalComponent } from "./non-standard/onboarding-modal/onboarding-modal.component";
import { MoreActionsComponent } from "./non-standard/more-actions/more-actions.component";
import { QRPaymentModalComponent } from "./non-standard/qrpayment-modal/qrpayment-modal.component";
import { QRCodeModule } from "angularx-qrcode";
import { ImageuploaderComponent } from "./imageuploader/imageuploader.component";
import { PopoverlistComponent } from "./popoverlist/popoverlist.component";
import { MapActionsComponent } from "./non-standard/map-actions/map-actions.component";
import { PhonescreenComponent } from "./phonescreen/phonescreen.component";
import { FilterItemsPipe } from "../pipes/filter-items/filter-items.pipe";
import { FeaturedCardComponent } from "./featured-card/featured-card.component";
import { MenuComponent } from "./non-standard/menu/menu.component";
//import { Angulartics2, Angulartics2Module } from "angulartics2";
import { DqInfiniteScroll } from "./non-standard/dq-infinite-scroll/dq-infinite-scroll";
import { ArraySomePipe } from "../pipes/array-some/array-some.pipe";
import { PipesModule } from "../pipes/pipes.module";
import { DirectivesModule } from "../directives/directives.module";
//import { FileUploadModule } from "@iplab/ngx-file-upload";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModifierModalComponent } from "./non-standard/modifier-modal-edit/modifier-modal.component";
import { CategoryModalComponent } from "./non-standard/category-modal-edit/category-modal.component";
import { IntervalFilter } from "./non-standard/category-modal-edit/intervalfilter.pipe";
import { PackageModalComponent } from "./non-standard/package-modal/package-modal.component";
import { OrderCardComponent } from "./order-card/order-card.component";
import { NotifstypeComponent } from "./non-standard/notifstype/notifstype.component";
import { BillingSetupComponent } from "./non-standard/billing-setup/billing-setup.component";
import { EventCardComponent } from "./event-card/event-card.component";
import { EventListComponent } from "./non-standard/event-list/event-list.component";
import { WidgetsModule } from "./widget.module";
import { PackageCardComponent } from "./package-card/package-card.component";
import { OrderDetailComponent } from "./order-detail/order-detail.component";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { BookingCardComponent } from "./booking-card/booking-card.component";
import { PaymentPageComponent } from "./payment-page/payment-page.component";
import { RecieptComponent } from "./reciept/reciept.component";
import { StatisticDateComponent } from "./statistic-date-picker/statistic-dates.component";
import { MenuModalComponent } from "./menu-refresh-modal/menu-modal.component";
import { EventSelectorComponent } from "./event-selector/event-selector.component";
import { TableItemComponent } from "./table-item/table-item.component";
import { StaffItemComponent } from "./staff-item/staff-item.component";
import { LetModule, PushModule } from "@ngrx/component";
import { EditGuestComponent } from "./edit-guest/edit-guest.component";
import { SearchGuestComponent } from "./search-guests/search-guest.component";
import { SelectPackageComponent } from "./select-package/select-package.component";
import { GratuityComponent } from "./non-standard/gratuity/gratuity.component";
import { PackageInclusionsComponent } from "./package-inclusions/package-inclusions.component";
import { TagComponent } from "./tag/tag.component";
import { WrapperComponent } from "./stat-wrapper/wrapper.component";
import { TagItemComponent } from "./tag-item/tag-item.component";
import { TagGroupsComponent } from "./tag-groups/tag-groups.component";
import { TagSearchComponent } from "./tag-search/tag-search.component";
import { TabReportComponent } from "./tab-report/tab-report.component";
import { GuestStatusComponent } from "./guest-status/guest-status.component";
import { MetricComponent } from "./metric/metric.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { WaitingComponent } from "./waiting-item/waiting-item.component";
import { PermissionsBlockComponent } from "./permissions-block/permissions-block.component";
import { RecieptChipComponent } from "./reciept-chip/reciept-chip.component";
import { ListChipComponent } from "./list-chip/list-chip.component";
import { BookingLineComponent } from "./booking-line/booking-line.component";
const components = [
  AddItemRowComponent,
  CartItemComponent,
  FabActionsComponent,
  SelectPackageComponent,
  HeaderCartComponent,
  HorizontalScrollerComponent,
  MenuLoggedOutComponent,
  ModifiersComponent,
  PageLoadingComponent,
  PaymentFormMethodComponent,
  SearchGuestComponent,
  NotifstypeComponent,
  PaymentFormGratuityComponent,
  PermissionsBlockComponent,
  SplashScreenComponent,
  filterfooter,
  ModalBaseComponent,
  MapComponent,
  EditGuestComponent,
  TableCardComponent,
  TreatMeInfluencerHeaderComponent,
  TreatMeInfluencerHeaderComponent,
  OnboardingModalComponent,
  MoreActionsComponent,
  WaitingComponent,
  ListChipComponent,
  QRPaymentModalComponent,
  ImageuploaderComponent,
  BookingLineComponent,
  PopoverlistComponent,
  MapActionsComponent,
  FeaturedCardComponent,
  PhonescreenComponent,
  MenuComponent,
  DqInfiniteScroll,
  GratuityComponent,
  ModifierModalComponent,
  CategoryModalComponent,
  IntervalFilter,
  PackageModalComponent,
  OrderCardComponent,
  BillingSetupComponent,
  EventCardComponent,
  PackageCardComponent,
  RecieptChipComponent,
  HorizontalScrollerComponent,
  PaymentPageComponent,
  RecieptComponent,
  OrderDetailComponent,
  BookingCardComponent,
  MenuModalComponent,
  StatisticDateComponent,
  EventSelectorComponent,
  TableItemComponent,
  StaffItemComponent,
  PackageInclusionsComponent,
  TagComponent,
  WrapperComponent,
  TagItemComponent,
  TagGroupsComponent,
  TagSearchComponent,
  TabReportComponent,
  GuestStatusComponent,
  MetricComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    RandomiseUrlPipeModule,
    QRCodeModule,
    NgxChartsModule,
    LetModule,
    PipesModule,
    //FileUploadModule,
    NzStepsModule,
    FormsModule,
    PushModule,
    LetModule,
    //Angulartics2Module,
    NgCircleProgressModule.forRoot({
      // set defaults here

      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    DirectivesModule,
  ],
  exports: components,
})
export class ComponentsModule {}
