<ion-content
  class="modal"
  color="secondary"
  (click)="edititem.dismiss(); addtable.dismiss()"
>
  <ion-list class="modal">
    <h1 color="secondary">Editing Package</h1>

    <ion-row>
      <ion-item class="name item-sett">
        <ion-label position="stacked">Name</ion-label>
        <ion-input
          type="text"
          [(ngModel)]="Package.name"
          name="title"
        ></ion-input>
      </ion-item>
      <ion-item class="price item-sett">
        <ion-label position="stacked">Amount of Guests</ion-label>
        <ion-input
          type="number"
          [(ngModel)]="Package.included_guests"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>
    </ion-row>

    <ion-row>
      <ion-item class="w35 item-sett">
        <ion-label position="stacked">Package Type</ion-label>
        <ion-select [(ngModel)]="type">
          <ion-select-option value="package"> Package Cost</ion-select-option>
          <ion-select-option value="upfront"> Upfront Spend </ion-select-option>
          <ion-select-option value="minimum"> Minimum Spend </ion-select-option>
          <ion-select-option value="minimumexc">
            Minimum Spend After Deposit
          </ion-select-option>
          <ion-select-option value="advanced"> Advanced </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item class="w35 item-sett" *ngIf="type != 'advanced'">
        <ion-label
          position="stacked"
          *ngIf="type == 'package' || type == 'upfront'"
          >Total</ion-label
        >
        <ion-label
          position="stacked"
          *ngIf="type == 'minimum' || type == 'minimumexc'"
          >Minimum Spend</ion-label
        >

        <ion-input
          type="text"
          [(ngModel)]="price"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>

      <ion-item class="price item-sett" *ngIf="type != 'advanced'">
        <ion-label position="stacked">Deposit Amount</ion-label>
        <ion-input
          type="text"
          [(ngModel)]="deposit"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>

      <ion-item class="w35 item-sett" *ngIf="type == 'advanced'">
        <ion-label position="stacked">Total Payment</ion-label>

        <ion-input
          type="text"
          [(ngModel)]="Package.price"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>

      <ion-item class="price item-sett" *ngIf="type == 'advanced'">
        <ion-label position="stacked">Deposit Payment</ion-label>
        <ion-input
          type="text"
          [(ngModel)]="Package.deposit"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>
      <ion-item class="price item-sett" *ngIf="type == 'advanced'">
        <ion-label position="stacked">Included Spend</ion-label>
        <ion-input
          type="text"
          [(ngModel)]="Package.included_spend"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>
      <ion-item class="price item-sett" *ngIf="type == 'advanced'">
        <ion-label position="stacked">Minimum Spend</ion-label>
        <ion-input
          type="text"
          [(ngModel)]="Package.min_spend"
          name="title"
          type="number"
        ></ion-input>
      </ion-item>
      <ion-item
        *ngIf="type != 'advanced'"
        lines="none"
        colo="none"
        class="transparent ion-margin-top ion-margin-bottom"
      >
        <ion-note *ngIf="type == 'package'"
          >Guests will be charged
          {{ deposit | currency: (currency$ | async) }} at checkout, the balance
          {{ price - deposit | currency: (currency$ | async) }}
          will be due later</ion-note
        >
        <ion-note *ngIf="type == 'minimum'"
          >Guests will be charged
          {{ deposit | currency: (currency$ | async) }} at checkout, this will
          be provided as credit to spend on the night where they will need to
          spend an extra
          {{ price - deposit | currency: (currency$ | async) }} for a total of
          at least
          {{ price | currency: (currency$ | async) }}
        </ion-note>
        <ion-note *ngIf="type == 'upfront'"
          >Guests will be charged
          {{ deposit | currency: (currency$ | async) }} at checkout, They will
          need to pay the balance
          {{ price - deposit | currency: (currency$ | async) }} at the door and
          they will be able to spend
          {{ price | currency: (currency$ | async) }} inside the venue
        </ion-note>
        <ion-note *ngIf="type == 'minimumexc'"
          >Guests will be charged
          {{ deposit | currency: (currency$ | async) }} at checkout, on the
          night they will need to spend at least
          {{ price | currency: (currency$ | async) }}
        </ion-note>
      </ion-item>
    </ion-row>
    <ion-list class="borderrad"
      ><ion-item-divider color="secondary"> Contents </ion-item-divider>
      <ion-list class="borderrad">
        <ng-container *ngFor="let item of Package.inclusions">
          <ion-item>
            <ion-row class="ion-justify-content-between widen">
              <ion-textarea
                rows="1"
                maxlength="20"
                placeholder="Name"
                [(ngModel)]="item.name"
              ></ion-textarea>
              <div class="endslot">
                <ion-chip *ngIf="item.mi_id != null" color="primary"
                  >Menu Item {{ item.menu_item_id }}</ion-chip
                >

                <ion-chip *ngIf="item.item_options != undefined" color="primary"
                  >Item Option</ion-chip
                >
                <ion-textarea
                  class="smallone ion-text-center"
                  rows="1"
                  placeholder="Quantity"
                  type="number"
                  [(ngModel)]="item.quantity"
                ></ion-textarea>
              </div>
            </ion-row>
            <ion-button
              class="centredbt"
              slot="end"
              fill="clear"
              (click)="removeItem(item)"
            >
              <ion-icon
                slot="icon-only"
                size="default"
                color="light"
                name="close-outline"
              ></ion-icon
            ></ion-button>
          </ion-item>
          <ion-item *ngIf="item.item_options != undefined">
            <div class="item-options">
              <ion-button
                fill="outline"
                size="default"
                [style.font-size.px]="14"
                (click)="
                  onlyItems = true; latestOnlyItem = item; edititem.present()
                "
              >
                <ion-icon slot="icon-only" name="add-outline"></ion-icon>
              </ion-button>
              <ion-button
                size="default"
                [style.font-size.px]="14"
                *ngFor="let miopt of item.item_options"
                fill="outline"
                (click)="removeItemOption(item, miopt)"
              >
                <ion-label>{{ (menu_item$(miopt) | async)?.name }}</ion-label>
                <ion-icon slot="end" name="trash-outline"></ion-icon>
              </ion-button>
            </div>
          </ion-item>
        </ng-container>
      </ion-list>
      <ion-item class="ion-margin-top borderrad" id="click-trigger">
        Add Item <ion-icon slot="end" name="add-outline"></ion-icon>
      </ion-item>
      <ion-popover
        size="cover"
        class="max-he"
        #edititem
        alignment="center"
        [backdropDismiss]="true"
        trigger="click-trigger"
        triggerAction="click"
        (willDismiss)="onlyItems = false"
      >
        <ng-template>
          <ion-header class="">
            <ion-toolbar>
              <ion-searchbar
                [(ngModel)]="searchbar"
                (ngModelChange)="search = searchbar.toLowerCase()"
              ></ion-searchbar>
            </ion-toolbar>
          </ion-header>
          <ion-content>
            <ion-list>
              <ion-item *ngIf="!onlyItems" (click)="AddItem()">
                <ion-label *ngIf="searchbar == ''">
                  Create Text Inclusion
                </ion-label>

                <ion-label *ngIf="searchbar != ''">
                  Create Text Inclusion "{{ searchbar }}"
                </ion-label>

                <ion-icon slot="end" name="text-outline"></ion-icon>
              </ion-item>
              <ion-item *ngIf="!onlyItems" (click)="AddItem(undefined, true)">
                <ion-label *ngIf="searchbar == ''">
                  Create Item Option
                </ion-label>

                <ion-label *ngIf="searchbar != ''">
                  Create Item Option "{{ searchbar }}"
                </ion-label>

                <ion-icon slot="end" name="cube-outline"></ion-icon>
              </ion-item>
              <ng-container *ngFor="let item of menu_items$ | async">
                <ion-item
                  *ngIf="item.name.toLowerCase().includes(search)"
                  (click)="AddItem(item)"
                >
                  <ion-label>{{ item.name }}</ion-label>
                  <ion-icon slot="end" name="add-outline"></ion-icon>
                </ion-item>
              </ng-container>
            </ion-list>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-list>
    <ion-list class="borderrad"
      ><ion-item-divider color="secondary" class="ion-margin-top">
        Tables
      </ion-item-divider>
      <ion-list class="borderrad">
        <ion-item *ngFor="let tableId of Package.table_ids">
          <ng-container *ngrxLet="table$(tableId) as table">
            <ion-label> {{ table.name }} </ion-label>

            <ion-label slot="end"> {{ table.table_id }} </ion-label>
            <ion-button
              class="centredbt"
              slot="end"
              fill="clear"
              (click)="removeTable(tableId)"
            >
              <ion-icon
                slot="icon-only"
                size="default"
                color="light"
                name="close-outline"
              ></ion-icon
            ></ion-button>
          </ng-container>
        </ion-item>
      </ion-list>
      <ion-item class="ion-margin-top borderrad" id="tbale-trigger">
        Add Table <ion-icon slot="end" name="add-outline"></ion-icon>
      </ion-item>
      <ion-popover
        size="cover"
        side="top"
        #addtable
        class="nolow"
        alignment="center"
        trigger="tbale-trigger"
        triggerAction="click"
        [backdropDismiss]="true"
      >
        <ng-template>
          <ion-header class="">
            <ion-toolbar>
              <ion-searchbar
                [(ngModel)]="searchbar"
                (ngModelChange)="search = searchbar.toLowerCase()"
              ></ion-searchbar>
            </ion-toolbar>
          </ion-header>
          <ion-content>
            <ion-list>
              <ng-container *ngFor="let table of tables$ | async">
                <ion-item
                  *ngIf="table.name.toLowerCase().includes(search)"
                  (click)="AddTable(table)"
                >
                  <ion-label>{{ table.name }}</ion-label>
                  <ion-icon slot="end" name="add-outline"></ion-icon>
                </ion-item>
              </ng-container>
            </ion-list>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-list>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar color="secondary">
    <ion-button
      *ngIf="Package.package_id != '0'"
      class=""
      color="primary"
      (click)="save()"
    >
      Save
    </ion-button>
    <ion-button
      *ngIf="Package.package_id != '0'"
      class=""
      color="primary"
      fill="outline"
      (click)="duplicate()"
    >
      Duplicate
    </ion-button>
    <ion-button
      *ngIf="Package.package_id != '0'"
      class=""
      fill="outline"
      color="danger"
      (click)="delete()"
    >
      Archive
    </ion-button>
    <ion-button
      *ngIf="Package.package_id == '0'"
      class=""
      (click)="save()"
      color="primary"
    >
      Create Item
    </ion-button>
    <ion-item color="none" slot="end"
      ><ion-label>Auto Approve Booking Requests</ion-label
      ><ion-checkbox
        [(ngModel)]="Package.auto_approve"
        slot="end"
      ></ion-checkbox
    ></ion-item>
  </ion-toolbar>
</ion-footer>
