import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DqApiResponse } from "src/app/models/queuebar-api";
import { map } from "rxjs/operators";
import { DateTime } from "luxon";
import { RegionsService } from "../regions/regions.service";
import {
  NotificationBroadcast,
  NotificationList,
} from "src/app/models/notification_models";

export interface DQNotification {
  noti_id: number;
  venue_id: number;
  noti_msg: string;
  isread: number;
  user_id: string;
  read_on: string;
  user_type: number;
  created_on: string;
  attended_by: string;
}
export interface DqNotificationGetSuccess extends DqApiResponse {
  status: "success";
  notificationlist: DQNotification[];
}
export interface DqNotificationGetError extends DqApiResponse {
  status: "failed";
  message: "no more notifications";
}
export type DqNotificationGetResponse =
  | DqNotificationGetSuccess
  | DqNotificationGetError;

export interface DqNotificationSendResponse extends DqApiResponse {
  status: "success" | "failed";
  message: string;
}

export interface DqSendNotificationParams {
  collection_point_id: string;
  venue_id: string;
  table_id: string;
  user_id: string;
}

export interface DqSendNotificationExtendedParams
  extends DqSendNotificationParams {
  user_type: string;
}

export interface DqAcceptNotificationResponse extends DqApiResponse {
  message: string;
}

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private http: HttpClient, private reg: RegionsService) {}

  acceptNotification(link: string) {
    return this.http.get<DqAcceptNotificationResponse[]>(link);
  }

  acceptNotificationOld(
    user_id: string,
    user_type: string,
    notification_id: string,
    venue_id: string
  ) {
    const path = "/admin/master/WS/hotel_ws/change_noti_status.php";
    return this.http
      .get<DqAcceptNotificationResponse[]>(`${this.reg.ServerURL()}${path}`, {
        params: {
          user_id,
          user_type,
          noti_id: notification_id,
          venue_id,
        },
      })
      .pipe(map(([result]) => result));
  }

  deleteNotification(notificationId: string) {
    const path = "/admin/master/WS/hotel_ws/delete_notification.php";
    return this.http
      .get<DqApiResponse[]>(`${this.reg.ServerURL()}${path}`, {
        params: { noti_id: notificationId },
      })
      .pipe(map(([response]) => response));
  }

  sendNotificationCallManager(params: DqSendNotificationParams) {
    return this.sendNotification({ user_type: "1", ...params });
  }
  sendNotificationCallDQ(params: DqSendNotificationParams) {
    return this.sendNotification({ user_type: "DQ", ...params });
  }

  sendNotificationCallSecurity(params: DqSendNotificationParams) {
    return this.sendNotification({ user_type: "3", ...params });
  }

  sendNotificationCallRunner(params: DqSendNotificationParams) {
    return this.sendNotification({ user_type: "2", ...params });
  }

  sendNotification({
    user_type,
    collection_point_id,
    venue_id,
    table_id,
    user_id,
  }: DqSendNotificationExtendedParams) {
    const now = DateTime.local();
    const path = "/v2/notification";
    return this.http.get<any>(`${this.reg.ServerURL()}${path}`, {
      params: {
        user_type,
        collection_point: collection_point_id,
        venue_id,
        table_id,
        staff_mem_id: user_id,
        curr_datetime: now.toFormat("hh:mm  a"), //curr_datetime=03:31%20%20PM
        noti_date: now.toFormat("hh:mm a dd/LL/yyyy"), //noti_date=03:31%20PM%2026/07/2019
      },
    });
  }

  sendManagerNotification(object, venue) {
    return this.http.post(
      `${this.reg.ServerURL()}/v4/notification?venue_id=${venue}`,
      object
    );
  }

  sendTestNotification(token: string) {
    const path = "/admin/master/WS/hotel_ws/test_notification";
    return this.http.get(`${this.reg.ServerURL()}${path}`, {
      params: { fcm_id: token },
    });
  }
}
