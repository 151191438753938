<ng-container
  *ngIf="recieptId != undefined && (recieptLoaded$ | async) == true; else load"
>
  <ion-list
    lines="none"
    class="recieptlist"
    *ngrxLet="reciept$(recieptId) | async as reciept"
  >
    <ng-container *ngIf="Actionable && reciept != undefined">
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'paid'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="success"
      >
        <ion-label color="light">Payment Completed</ion-label>
        <ion-icon
          slot="end"
          color="light"
          name="checkmark-done-outline"
        ></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'overpaid'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="danger"
      >
        <ion-label color="light">Overpaid</ion-label>
        <ion-icon
          slot="end"
          color="light"
          name="alert-circle-outline"
        ></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'unpaid'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="primary"
      >
        <ion-label color="light">Unpaid</ion-label>
        <ion-icon slot="end" color="light" name="add-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'partial'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="primary"
      >
        <ion-label color="light">Partially Paid</ion-label>
        <ion-icon slot="end" color="light" name="add-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="OpenPayments()"
        class="paymc"
        *ngIf="reciept.receipt_state == 'refunded'"
        expand="block"
        [fill]="outline ? 'outline' : 'solid'"
        color="danger"
      >
        <ion-label color="light">Payment Refunded</ion-label>
        <ion-icon slot="end" color="light" name="arrow-undo-outline"></ion-icon>
      </ion-button>
    </ng-container>

    <ion-item>
      <ion-label class="notop"> Total </ion-label>
      <ion-label class="notop" slot="end" *ngIf="reciept">
        {{ reciept.total | currency: (currency$ | async) }}
      </ion-label>
    </ion-item>
    <ion-item class="sosmall">
      <ion-label class="notop"> Tip </ion-label>
      <ion-label class="notop" slot="end" *ngIf="reciept">
        {{ reciept.tip_paid | currency: (currency$ | async) }}
      </ion-label>
    </ion-item>
    <ng-content></ng-content>

    <ng-container *ngIf="reciept">
      <div class="center" *ngIf="showEmpty && reciept.payments.length == 0">
        <ion-label class="empty-text">No Payments</ion-label>
      </div>
      <ion-item-sliding
        *ngFor="let charge of reciept.payments; let indexOfelement = index"
      >
        <ion-item [class.distance]="charge.status == 'pending'">
          <div
            class="vl"
            [class.firstn]="indexOfelement == 0"
            [class.lastn]="indexOfelement == reciept.payments.length - 1"
            slot="start"
          ></div>
          <div slot="start">
            <div class="iconholder" [class]="charge.status">
              <ion-icon
                *ngIf="charge.status != 'refunded'"
                [name]="charge.icon"
              ></ion-icon>
            </div>
            <div
              class="iconholder overtop refundedextra"
              *ngIf="charge.status == 'refunded'"
            >
              <ion-icon name="arrow-undo-outline"></ion-icon>
            </div>
          </div>
          <ion-col>
            <ion-note color="danger" *ngIf="charge.status == 'refunded'"
              >Refunded</ion-note
            >
            <ion-label>
              <b>{{ charge.name }}</b>
              <em> - {{ charge.time | date: "shortTime" }}</em>
            </ion-label>
            <ion-note class="smallfon">{{ charge.person }}</ion-note>
          </ion-col>
          <div class="numbers" slot="end">
            <ion-note
              [color]="charge.status == 'refunded' ? 'danger' : ''"
              *ngIf="charge.amount > 0"
            >
              {{ charge.amount | currency: (currency$ | async) }}
            </ion-note>
            <ion-note
              [color]="charge.status == 'refunded' ? 'danger' : ''"
              *ngIf="charge.tip_paid > 0"
              class="smallfon"
            >
              {{ charge.tip_paid | currency: (currency$ | async) }}
            </ion-note>
          </div>
        </ion-item>
        <ion-item-options side="start" *ngIf="charge.status == 'paid'">
          <ion-item-option color="secondary" (click)="recieptSend(charge)">
            <ion-icon slot="icon-only" name="send-outline"></ion-icon>
          </ion-item-option>
        </ion-item-options>
        <ion-item-options side="end" *ngIf="charge.refundable">
          <ion-item-option
            *ngIf="charge.status != 'pending'"
            color="danger"
            (click)="refundPayment(charge)"
          >
            <ion-spinner *ngIf="cancelling"></ion-spinner>
            <ion-icon
              slot="icon-only"
              *ngIf="!cancelling"
              name="arrow-undo-outline"
            ></ion-icon>
          </ion-item-option>
          <ion-item-option
            *ngIf="charge.status == 'pending'"
            color="secondary"
            (click)="CancellPayment(charge)"
          >
            <ion-spinner *ngIf="cancelling"></ion-spinner>
            <ion-icon
              slot="icon-only"
              *ngIf="!cancelling"
              name="stop-circle-outline"
            ></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ng-container>
  </ion-list>
</ng-container>

<ng-template #load>
  <ion-list lines="none" class="recieptlist">
    <ng-container *ngIf="Actionable">
      <ion-skeleton-text
        [animated]="true"
        class="paymc expand"
        [style.height]="'45px'"
      ></ion-skeleton-text>
    </ng-container>
    <ion-item>
      <ion-label class="notop"> Total </ion-label>
      <ion-label class="notop" slot="end">
        <ion-skeleton-text
          [animated]="true"
          [style.width]="'60px'"
          [style.height]="'20px'"
        ></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ion-item class="sosmall">
      <ion-label class="notop"> Tip </ion-label>
      <ion-label class="notop" slot="end">
        <ion-skeleton-text
          [animated]="true"
          [style.width]="'60px'"
          [style.height]="'20px'"
        ></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ion-list>
</ng-template>
