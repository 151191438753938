import { Component, OnInit, Input } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import {
  NotificationService,
  DqNotificationSendResponse,
} from "src/app/services/notification/notification.service";
import { TablesFacadeService } from "src/app/facades/tables-facade/tables-facade.service";
import { environment } from "src/environments/environment";
import { ToastController } from "@ionic/angular";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { TableState } from "src/app/services/sync-engine/table-state/table.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

@Component({
  selector: "app-fab-actions",
  templateUrl: "./fab-actions.component.html",
  styleUrls: ["./fab-actions.component.scss"],
})
export class FabActionsComponent implements OnInit {
  @Input() tableId: string;

  constructor(
    private notificationSvc: NotificationService,
    private tableFacade: TablesFacadeService,
    private toastCtrl: ToastController,
    private analy: AnalyticsService,
    private tableSt: TableState,
    private settingSt: SettingsState
  ) {}

  RequestDQ = this.analy.getFeatureFlag("request_support", false);

  ngOnInit() {}

  callManager() {
    Haptics.impact();

    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallManager({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request Manager", result);
          this.showToastForResponse(result);
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }
  callDQ() {
    Haptics.impact();

    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallDQ({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request DQ", result);
          this.showToastForResponse(result);
          Haptics.selectionEnd();
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }

  callSecurity() {
    Haptics.impact();

    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallSecurity({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request Security", result);
          this.showToastForResponse(result);
          Haptics.selectionEnd();
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }

  callRunner() {
    Haptics.impact();
    this.getUserAndTableDetails()
      .pipe(
        take(1),
        switchMap(([user, table]) =>
          this.notificationSvc.sendNotificationCallRunner({
            user_id: user.user.user_id,
            venue_id: user.currentVenue + "",
            table_id: this.tableId,
            collection_point_id: table.cp_id,
          })
        )
      )
      .subscribe(
        (result) => {
          this.analy.addEvent("Request Runner", result);
          this.showToastForResponse(result);
          Haptics.selectionEnd();
        },
        (error) => this.showToast("Your request was not sent. Please try again")
      );
  }

  getUserAndTableDetails() {
    return combineLatest([
      this.settingSt.state.select(),
      this.tableSt.table$(this.tableId),
    ]);
  }

  showToastForResponse(result: DqNotificationSendResponse) {
    if (result.status.toLowerCase() === "success") {
    } else {
      console.error(FabActionsComponent.name, result);
      this.showToast("Your request was not sent. Please try again");
    }
  }

  async showToast(message) {
    const toast = await this.toastCtrl.create({
      header: message,
      duration: environment.defaultToastDuration,
      position: "top",
      buttons: [
        {
          role: "cancel",
          icon: "close-circle-outline",
          cssClass: "toast-close-button",
        },
      ],
    });
    await toast.present();
  }
}
