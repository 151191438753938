import { Pipe, PipeTransform } from "@angular/core";
import { DateTime, ToRelativeUnit } from "luxon";

@Pipe({
  name: "timeAgo",
})
export class TimeAgoPipe implements PipeTransform {
  transform(
    timeString: string,
    fallbackFormat: string = "d MMM yy - h:mm a",
    updateTrigger?: number
  ): string {
    const time = DateTime.fromISO(timeString);
    const now = DateTime.local();
    if (now.diff(time, "days").days < 2) {
      return time.toRelative();
    } else if (fallbackFormat.includes("rel")) {
      return time.toRelative({
        style: 'short',
        
        unit: fallbackFormat.replace("rel", "") as ToRelativeUnit
      }).replace(" ago", "");
    } else {
      return time.toFormat(fallbackFormat);
    }
  }
}
