import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { CartFacadeService } from "src/app/facades/cart-facade/cart-facade.service";
import {
  DqGarnish,
  DqMixture,
  DqGarnishBasic,
  DqMixtureBasic,
} from "src/app/services/mixture/mixture.service";
import { DqOrderStatus } from "src/app/services/order/order.service";
import {
  DqConsumerGarnish,
  DqConsumerMixture,
  DqConsumerModifier,
} from "src/app/services/consumer-table/consumer-table.service";
import { DqModifierGroup } from "src/app/models/cart.model";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import {
  AlertController,
  IonSearchbar,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { BookingService } from "src/app/services/booking/booking.service";
import { BookingGuest } from "src/app/models/bookings";
import { Router } from "@angular/router";
import { RegionsService } from "src/app/services/regions/regions.service";
import { GuestState } from "src/app/services/sync-engine/guest-state/guest.service";

@Component({
  selector: "app-search-guest",
  templateUrl: "./search-guest.component.html",
  styleUrls: ["./search-guest.component.scss"],
})
export class SearchGuestComponent implements OnInit, OnChanges {
  @Output() selectedGuest: EventEmitter<BookingGuest> = new EventEmitter();

  @Input() createAllowed = true;
  @Output() createGuest = new EventEmitter();
  @ViewChild("searchbar") searchbar: IonSearchbar
  guests = [];
  guestRaw = "";
  guestSearch = "";
  saving = false;
  constructor(
    private cartFacade: CartFacadeService,
    private analy: AnalyticsService,
    private modal: ModalController,
    private booqSvc: BookingService,
    private alert: AlertController,
    private router: Router,
    private platform: Platform,
    private reg: RegionsService,
    private toastCtrl: ToastController,
    private guestSt: GuestState
  ) {}
  //guestsearching = false; booking.card.name = guest.guest_name; booking.card.contact = guest.guest_mobile_num

  async ngOnInit() {
    this.createGuest.observed;
    //wait for searchbar not to be null and then focus on it
    setTimeout(() => {
      if (this.searchbar) {
        this.searchbar.setFocus();
      }
    }, 300);
  
  }

  ngOnChanges(changes: SimpleChanges) {}

  dismiss() {
    this.modal.dismiss();
  }

  selectGuest(guest) {
    this.guestSt.state.set({
      [guest.guest_id]: guest,
    });
    this.selectedGuest.emit(guest);
    this.modal.dismiss(guest);
  }

  create() {
    this.createGuest.emit();
    this.modal.dismiss("create");
  }

  searchGuests() {
    this.saving = true;
    this.booqSvc.searchGuests(this.guestSearch).subscribe((guests) => {
      this.guests = guests.items;
      this.saving = false;
    });

    this.analy.addEvent("Searched Guests", this.guestSearch);
  }
}
