import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AlertController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
  ViewWillEnter,
} from "@ionic/angular";
import { Observable, Subject, timer } from "rxjs";
import { retry, share, switchMap, takeUntil } from "rxjs/operators";
import {
  DqOrderPaymentResponse,
  DqOrderPaymentResponseSuccess,
  OrderService,
} from "src/app/services/order/order.service";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { NgNavigatorShareService } from "ng-navigator-share";
import { environment } from "src/environments/environment";
import { PaymentService } from "src/app/services/payment/payment.service";

import {
  PaymentMethod,
  PaymentUserActionRequest,
  Receipt,
} from "src/app/models/payment_models";
import { ActivatedRoute, Router } from "@angular/router";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { RegionsService } from "src/app/services/regions/regions.service";
import { RecieptState } from "src/app/services/sync-engine/reciept-state/reciept.service";
import { SettingsState } from "src/app/services/sync-engine/settings-state/settings-state.service";
@Component({
  selector: "app-reciept",
  templateUrl: "./reciept.component.html",
  styleUrls: ["./reciept.component.scss"],
})
export class RecieptComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() paymentOpen = new EventEmitter<string>();
  reciept$ = this.recieptSt.receipt$;
  recieptLoaded$ = this.recieptSt.loaded$;
  constructor(
    public modalCtrl: ModalController,
    public orderSvc: OrderService,
    public platform: Platform,
    public ngNavigatorShareService: NgNavigatorShareService,
    public toastCtrl: ToastController,
    private paymentSvc: PaymentService,
    private router: Router,
    private relroute: ActivatedRoute,
    private menuSvc: MenuController,
    private pusher: PusherService,
    private analy: AnalyticsService,
    private reg: RegionsService,
    private recieptSt: RecieptState,
    private settingSt: SettingsState,
    private alertCtrl: AlertController
  ) {}
  @Input() recieptId: string;
  @Input() refresh?: EventEmitter<string> = new EventEmitter<string>();
  @Input() Actionable: boolean = false;

  @Input() showEmpty: boolean = false;
  @Input() outline: boolean = false;

  currency$ = this.settingSt.currencyCode$;

  keepGoing = false;

  async ngOnDestroy() {
    if (this.keepGoing) {
      this.keepGoing = false;
      return;
    }
    await this.pusher.unsubscribeChannel("reciept", this.recieptId);
  }
  async ngAfterViewInit() {
    this.refresh.subscribe(async (data) => {
      await this.recieptSt.refresh();
    });
  }
  OpenPayments() {
    //if platform is tablet
    this.paymentSvc.paymentOnReciept(this.recieptId);
    if (!this.platform.is("tablet")) {
      this.keepGoing = true;
    }
  }
  //added
  async ngOnInit() {
    //get all histroy except last one froms static list
  }

  cancelling = false;
  async refundPayment(payment) {
    this.cancelling = true;
    let action: PaymentUserActionRequest = {
      old_state: payment.refundable.old_state,
      new_state: payment.refundable.new_state,
    };

    let act = await this.paymentSvc
      .postPaymentAction(this.recieptId, payment.pi_uuid, action)
      .toPromise();
    console.log("refundPayment: ", act);
    if (payment.view_process != undefined && payment.view_process == true) {
      this.router.navigate([this.reg.getRegion(), "pay", this.recieptId], {
        queryParams: { payment: payment.pi_uuid },
      });
    }

    this.cancelling = false;
  }

  async recieptSend(payment) {
    //show alert and ask to enter email or phone number
    //send email or phone number to reciept

    let al = await this.alertCtrl.create({
      header: "Send Receipt",
      message: "Enter Email or Phone Number",
      inputs: [
        {
          name: "email",
          type: "email",
          placeholder: "Email or Phone",
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Send",
          handler: async (data) => {
            console.log("send reciept", data);

            let act = await this.paymentSvc
              .sendPaymentReceipt(payment.pi_uuid, data.email)
              .toPromise();
            console.log("refundPayment: ", act);
            //show toast
            let toast = await this.toastCtrl.create({
              message: "Receipt Sent to customer",
              duration: 3000,
              position: "top",
            });
            await toast.present();
          },
        },
      ],
    });
    await al.present();
  }

  async CancellPayment(payment) {
    this.cancelling = true;
    let action: PaymentUserActionRequest = {
      old_state: payment.status,
      new_state: "cancelled",
    };

    let act = await this.paymentSvc
      .postPaymentAction(this.recieptId, payment.pi_uuid, action)
      .toPromise();
    console.log("refundPayment: ", act);
    this.cancelling = false;
  }
}
